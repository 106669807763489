import React from 'react';
import { useTranslation } from 'react-i18next';
import './AboutUs.scss';
import ste from '../../assets/images/pictures/profile/ste.png';
import vivi from '../../assets/images/pictures/profile/vivi.png';
import ColumnDivider from '../column-divider/ColumnDivider';
import mail from '../../assets/images/icons/mail.svg';
import phone from '../../assets/images/icons/phone.svg';
import { Spinner } from '../Countdown/Spinner';

const renderContactsInfo = (name: string, tel: string, email: string) => {
  return (
    <div className="contactsInfo">
      <span className="name">{name}</span>
      <span>
        <img width={30} src={phone} alt="phone" />
        {tel}
      </span>
      <span>
        <img width={28} src={mail} alt="mail" />
        {email}
      </span>
    </div>
  );
};

function AboutUs() {
  const { t } = useTranslation('common');

  return (
    <div className="section" id="aboutus">
      <h2 className="contactus">
        {t('contactus.title', {
          framework: 'react-i18next',
        })}
      </h2>
      <p className="moreinfo">
        {t('contactus.summary', {
          framework: 'react-i18next',
        })}
      </p>
      <div id="brideandgroom">
        <div id="bride">
          <img
            alt="Viviana"
            title="Viviana"
            className="profilePicture"
            src={vivi}
          />
          {renderContactsInfo(
            "Viviana D'Otolo",
            '+41 76 208 71 12',
            'vivianadotolo122@gmail.com'
          )}
        </div>
        <Spinner></Spinner>
        <div id="groom">
          <img
            title="Stefano"
            alt="Stefano"
            className="profilePicture"
            src={ste}
          />
          {renderContactsInfo(
            'Stefano Pecoraro',
            '+41 76 558 34 40',
            'stefano.pecoraro@protonmail.com'
          )}
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
