import React from 'react';
import './Gift.scss';
import Slider from '../image-slider/ImageSlider';
import australia from '../../assets/images/pictures/registry/australia.jpeg';
import giappone from '../../assets/images/pictures/registry/giappone.jpeg';
import giappone2 from '../../assets/images/pictures/registry/giappone2.jpeg';
import maldive from '../../assets/images/pictures/registry/maldive.jpeg';
import malesia from '../../assets/images/pictures/registry/malesia.jpeg';
import marocco from '../../assets/images/pictures/registry/marocco.jpeg';
import sriLanka from '../../assets/images/pictures/registry/sriLanka.jpeg';
import sriLanka2 from '../../assets/images/pictures/registry/sriLanka2.jpeg';
import eur from '../../assets/images/icons/eur-account.svg';
import ch from '../../assets/images/icons/ch-account.svg';
import ContributeCard from './ContributeCard';
import IbanCard from './IbanCard';
import { useTranslation } from 'react-i18next';

const images = [
  australia,
  giappone,
  giappone2,
  maldive,
  malesia,
  marocco,
  sriLanka,
  sriLanka2,
].map((i) => {
  return {
    img: i,
  };
});

function Gift() {
  const { t } = useTranslation('common');
  return (
    <section className=" section" id="gifts">
      <div className="main">
        <h2>
          {t('gifts.title', {
            framework: 'react-i18next',
          })}
        </h2>
        <p>
          {t('gifts.paragraph', {
            framework: 'react-i18next',
          })}
        </p>
        <ContributeCard
          front={<span></span>}
          back={
            <div className="ibans">
              <IbanCard
                accountHolder="Stefano Pecoraro"
                img={eur}
                iban="DE44100110012628173961"
                bic="NTSBDEB1XXX"
                currency="EUR"
              ></IbanCard>

              <IbanCard
                accountHolder="Viviana D'Otolo"
                img={ch}
                iban="CH640024724719458640M"
                bic="UBSWCHZH80A"
                currency="CHF"
              ></IbanCard>
            </div>
          }
        ></ContributeCard>
      </div>
      <Slider
        items={images}
        autoplay={false}
        navigation
        scrollbar={false}
      ></Slider>
    </section>
  );
}

export default Gift;
