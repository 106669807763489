import React from 'react';
import './button.scss';

interface ButtonProps {
  onClick: Function;
  label: string;
  type: 'primary' | 'secondary';
  disabled?: boolean;
}
const Button: React.FC<ButtonProps> = ({
  onClick,
  label,
  type,
  disabled = false,
}) => {
  return (
    <button
      disabled={disabled}
      className={`button button--${type}`}
      onClick={() => onClick()}
    >
      {label}
    </button>
  );
};

export default Button;
