import { RsvpState, RSVPStates, StartState } from './rsvp-states';

export type RSVPData = {
  name: string;
  phonePrefix: string;
  phone: string;
  canParticipate: boolean;
  nameOfAllGuests: string[];
  kids: number;
  foodRestrictionsOrPreferences: string;
  accomodationNeeded: boolean;
  transportNeeded: boolean;
  consentGiven: boolean;
};

type MyPartialType<Type> = {
  // For every existing property inside the type of Type
  // convert it to be a ?: version
  [Property in keyof Type]?: Type[Property];
};

export type RSVPDataForEdit = MyPartialType<RSVPData>;

export type KeysMatching<T, V> = {
  [K in keyof T]: T[K] extends V ? K : never;
}[keyof T];

export type RsvpDataKeys = keyof RSVPData;

export type RsvpUpdate = {
  key: keyof RSVPData;
  value: string | number | boolean | string[];
};
type DataUpdateListener = (data: RSVPDataForEdit) => void;
export default class Rsvp {
  private states: RsvpState[] = [];
  private stateChangeSubscribers: Function[] = [];
  private dataUpdateSubscribers: DataUpdateListener[] = [];
  private rsvpData: RSVPDataForEdit = {};

  constructor() {
    const start = new StartState(this);
    this.states.push(start);
  }

  private get currentState() {
    return this.states[this.states.length - 1];
  }

  get currentStateId(): RSVPStates {
    return this.currentState.stateId;
  }

  getRsvpData(): RSVPDataForEdit {
    return { ...this.rsvpData };
  }

  private notifyStateChange() {
    this.stateChangeSubscribers.forEach((s) => {
      s();
    });
  }

  changeState(newState: RsvpState) {
    this.states.push(newState);
    this.notifyStateChange();
  }

  updateRsvpData(newValue: RsvpUpdate) {
    this.rsvpData = { ...this.rsvpData, ...{ [newValue.key]: newValue.value } };
    this.dataUpdateSubscribers.forEach((s) => {
      s(this.rsvpData);
    });
  }

  next() {
    this.currentState.next();
  }
  previous() {
    this.states.pop();
    this.notifyStateChange();
  }

  render(): JSX.Element {
    return this.currentState.render();
  }

  canDoNext(): boolean {
    return this.currentState.canDoNext();
  }

  canDoPrevious(): boolean {
    return this.currentState.canDoPrevious();
  }

  listenToStateChangeEvent(listener: Function) {
    this.stateChangeSubscribers.push(listener);
  }

  listenToDataUpdateEvent(listener: DataUpdateListener) {
    this.dataUpdateSubscribers.push(listener);
  }
}
