import { AxiosResponse } from 'axios';
import { http } from './Http';
import jwt_decode from 'jwt-decode';
import { Roles } from './roles';

interface LoginResponse {
  token: string;
}

const TOKEN_KEY = 'accessToken';
class AuthService {
  private tokenExpireListeners: Function[] = [];
  private isTokenExpired = true;
  private timeout;

  constructor() {
    this.setTokenExpirationHandling();
  }

  private notifyTokenExpiration = () => {
    this.tokenExpireListeners.forEach((l) => {
      l();
    });
  };

  private clearExpTokenTimeout() {
    if (!!this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  private setTokenExpirationHandling() {
    try {
      this.clearExpTokenTimeout();
      const token = this.getToken();
      if (token === null || token === undefined || token === '') {
        this.isTokenExpired = true;
        return;
      }
      const decoded = jwt_decode(token) as { exp?: number };
      if (!Number.isInteger(decoded.exp as number)) {
        this.isTokenExpired = true;
        this.notifyTokenExpiration();
        return;
      }
      const expInMillis = decoded.exp * 1000;
      const now = new Date();
      const tokenExpDate = new Date(expInMillis);
      this.isTokenExpired = now.getTime() >= tokenExpDate.getTime();
      if (!this.isTokenExpired) {
        const diff = tokenExpDate.getTime() - now.getTime();
        this.timeout = setTimeout(() => {
          this.notifyTokenExpiration();
          this.clearTokenFromStorage();
        }, diff);
      }
    } catch {
      this.clearTokenFromStorage();
    }

  }

  private clearTokenFromStorage() {
    localStorage.removeItem(TOKEN_KEY);
  }

  private getToken(): string | null {
    return localStorage.getItem(TOKEN_KEY);
  }

  get userRole() {
    const tk = this.getToken();
    if (!this.isTokenExpired && tk !== undefined && tk !== null && tk !== '') {
      const decoded = jwt_decode(tk) as { role?: Roles };
      return decoded.role;
    }
    return undefined;
  }

  get sessionExpired() {
    return this.isTokenExpired;
  }

  async login(passcode: string) {
    const resp = await http.post<
      { passcode: string },
      AxiosResponse<LoginResponse>
    >('/login', {
      passcode: passcode,
    });
    localStorage.setItem(TOKEN_KEY, resp.data.token);
    this.setTokenExpirationHandling();
  }

  logout() {
    this.clearTokenFromStorage();
  }

  listenOnTokenExpiration(listener: Function) {
    this.tokenExpireListeners.push(listener);
    if (this.isTokenExpired) {
      this.notifyTokenExpiration();
    }
  }
}

export const authService = new AuthService();
