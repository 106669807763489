export enum Routes {
  LOGIN = '/login',
  ADMIN = '/admin',
  HOME = '/',
}

const pathToRoutes = {
  '/login': Routes.LOGIN,
  '/': Routes.HOME,
  '/admin': Routes.ADMIN,
};

export function getRouteFromPath(path: string): Routes | undefined {
  return pathToRoutes[path];
}
