import * as React from 'react';
import adminService, { MessageModel } from '../../services/AdminService';
import ResponsesDataGrid from './ResponsesGrid';
import SmsMessagesGrid from './SmsMessagesGrid';
import './admin.scss';

interface IAdminProps {}

const Admin: React.FunctionComponent<IAdminProps> = () => {
  const [responses, setResponses] = React.useState([]);
  const [messages, setMessages] = React.useState<{
    [key: string]: MessageModel[];
  }>({});
  const [selectedMessages, setSelectedMessages] = React.useState<
    MessageModel[]
  >([]);

  async function loadRSVPs() {
    const data = await adminService.getGuestResponses();
    setResponses(data);
  }
  async function loadMessages() {
    const msgByPhone: { [key: string]: MessageModel[] } = {};
    const data = await adminService.getMessages();
    data.forEach((m) => {
      if (msgByPhone[m.phoneNumber] === undefined) {
        msgByPhone[m.phoneNumber] = [];
      }
      msgByPhone[m.phoneNumber].push(m);
    });

    setMessages(msgByPhone);
  }
  React.useEffect(() => {
    loadRSVPs();
    loadMessages();
  }, []);

  return (
    <main className="admin">
      <h3>Admin</h3>
      <ResponsesDataGrid
        onSelect={(phone) => {
          let sel = messages[phone];
          if (sel === undefined) {
            sel = [];
          }
          setSelectedMessages(sel);
        }}
        responses={responses}
      ></ResponsesDataGrid>

      <SmsMessagesGrid messages={selectedMessages}></SmsMessagesGrid>
    </main>
  );
};

export default Admin;
