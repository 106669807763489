import React from 'react';

function NotFound() {
  return (
    <div id="notfound">
      <p>ERROR</p>
      <p>Page not found</p>
      <a href="/">
        <button>Back to homepage</button>
      </a>
    </div>
  );
}

export default NotFound;
