import { Select, CaretIcon, ModalCloseButton } from 'react-responsive-select';
import {
  getCountries,
  getCountryCallingCode,
} from 'react-phone-number-input/input';
import 'react-responsive-select/dist/react-responsive-select.css';
import en from 'react-phone-number-input/locale/en.json';
import it from 'react-phone-number-input/locale/it.json';
import { Country } from 'react-phone-number-input';
import i18next from 'i18next';
import './phone-country-select.scss';
import { useTranslation } from 'react-i18next';

const PREFERRED_COUNTRIES: Country[] = ['CH', 'IT', 'DE', 'FR'];
const isPreferredCountry = (code: Country) => {
  return PREFERRED_COUNTRIES.includes(code);
};
const getOptions = (t: Function) => {
  let labels = en;
  if (i18next.language.toLowerCase() === 'it') {
    labels = it;
  }

  const otherCountries = getCountries().filter((c) => !isPreferredCountry(c));
  const countries = PREFERRED_COUNTRIES.concat(otherCountries);

  const options = countries.map((country) => {
    const callingCode = getCountryCallingCode(country) as string;
    const label = labels[country];
    return {
      value: callingCode,
      text: callingCode,
      markup: (
        <div className="option">
          <img
            className="flag"
            alt={label}
            src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${country.toUpperCase()}.svg`}
          />

          <span>{label}</span>
        </div>
      ),
    };
  });
  return [{ value: '', text: t('RSVP.countrySelect') }].concat(options);
};

export default function PhoneCountrySelect({ value, onChange }) {
  const { t } = useTranslation('common');
  return (
    <Select
      selectedValue={value}
      name="countrySelect"
      modalCloseButton={<ModalCloseButton />}
      options={getOptions(t)}
      caretIcon={<CaretIcon />}
      onChange={(newValue) => {
        onChange((newValue as any).value);
      }}
    />
  );
}
