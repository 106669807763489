import React from 'react';
import './App.scss';

//importing children components
import Navbar from './components/Navbar/Navbar';
import Hero from './components/Hero/Hero';
import Location from './components/Location/Location';
import AboutUs from './components/AboutUs/AboutUs';
import Gift from './components/Gift/Gift';
import { useTranslation } from 'react-i18next';
import Sectiondivider from './components/section-divider/SectionDivider';
import ScrollToTop from './components/scroll-to-top/ScrollToTop';
import RSVPWizard from './components/rsvp/rsvp-wizard';
import { useAuth } from './auth';
import { authService } from './services/AuthService';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import Button from './components/button/Button';
import { EndSection } from './components/end-section/EndSection';
import { Roles } from './services/roles';
import { getPermissionsForRole, Permissions } from './services/permissions';

function canSendRsvp(role: Roles) {
  const permissions = getPermissionsForRole(role);
  return permissions >= Permissions.CAN_SEND_RSVP;
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 3,
  },
};

export default function App() {
  const { userRole } = useAuth();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  React.useEffect(() => {
    authService.listenOnTokenExpiration(() => {
      setIsOpen(true);
    });
  }, []);

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <Navbar />
      <Hero />

      <Sectiondivider></Sectiondivider>
      <Location />
      <Sectiondivider></Sectiondivider>

      {canSendRsvp(userRole) && (
        <>
          <RSVPWizard></RSVPWizard>
          <Sectiondivider></Sectiondivider>
        </>
      )}

      <AboutUs />
      <Sectiondivider></Sectiondivider>

      <Gift />
      <Sectiondivider></Sectiondivider>

      <EndSection></EndSection>
      <ScrollToTop></ScrollToTop>
      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        style={customStyles}
        contentLabel="Example Modal"
        onRequestClose={closeModal}
        onAfterClose={() => {
          navigate('/login', { replace: true });
        }}
      >
        <h2>{t('session.title')}</h2>
        <span>{t('session.loginExpired')}</span>
        <Button
          type="primary"
          label={t('session.loginButton')}
          onClick={() => {
            closeModal();
          }}
        ></Button>
      </Modal>
    </>
  );
}
