import React from 'react';
import './venue-info.scss';

interface VenueCardProps {
  time: string;
  location: string;
  street: string;
  city: string;
  region: string;
  icon: string;
}

export function VenueInfo({
  time,
  location,
  street,
  city,
  region,
  icon,
}: VenueCardProps) {
  return (
    <div className="venue-info">
      <img src={icon} alt={location}></img>
      <span className="location">{location}</span>
      <span className="time">{time}</span>
      <span className="street"> {street} </span>
      <span className="city"> {city} </span>
      <span className="region"> {region} </span>
    </div>
  );
}
