import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { authService } from './services/AuthService';
import { isRouteAllowedForRole } from './services/permissions';
import { Roles } from './services/roles';
import { getRouteFromPath, Routes } from './services/routes';

interface AuthContextType {
  userRole: Roles;
  signin: (user: string, callback: Function) => void;
  signout: (callback: Function) => void;
}

export const AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [userRole, setUserRole] = React.useState<Roles>(authService.userRole);

  const signin = (passcode: string, callback: Function) => {
    return authService
      .login(passcode)
      .then(() => {
        setUserRole(authService.userRole);
        callback();
      })
      .catch((e) => {
        callback(e);
      });
  };

  const signout = (callback: Function) => {
    authService.logout();
    callback();
  };

  const value = {
    userRole,
    signin,
    signout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}

export function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    const route = getRouteFromPath(location.pathname);
    if (route === undefined) {
      navigate(Routes.LOGIN, { replace: true });
    }
    const role = auth.userRole;
    if (!isRouteAllowedForRole(route, role)) {
      navigate(Routes.LOGIN, { replace: true });
    }
  }, [auth.userRole, location.pathname, navigate]);

  return children;
}
