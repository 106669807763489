import React, { FunctionComponent } from 'react';
import './Login.scss';
//handling the translations
import i18next from '../i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth';
import { Spinner } from '../Countdown/Spinner';
import Flags from 'country-flag-icons/react/3x2';
import Button from '../button/Button';
import Lottie from 'react-lottie';
import hugAnimationData from '../../assets/lottie-animations/couple-riding-vespa.json';
import Emoji from '../emoji/Emoji';
import { useTranslation } from 'react-i18next';

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const { t } = useTranslation('common');

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: hugAnimationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  let from = (location.state as any)?.from?.pathname || '/';

  function handleSubmit(password) {
    setLoading(true);
    auth.signin(password, (err) => {
      setLoading(false);
      if (err) {
        setError(true);
        return;
      }
      // Send them back to the page they tried to visit when they were
      // redirected to the login page. Use { replace: true } so we don't create
      // another entry in the history stack for the login page.  This means that
      // when they get to the protected page and click the back button, they
      // won't end up back on the login page, which is also really nice for the
      // user experience.
      navigate(from, { replace: true });
    });
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(password);
    }
  };

  return (
    <>
      <div className="animation-container">
        <Lottie options={defaultOptions} width={'100%'} />
      </div>
      <div className="loginContainer">
        <div id="languages">
          <Flags.IT onClick={() => i18next.changeLanguage('it')}></Flags.IT>
          <Flags.GB onClick={() => i18next.changeLanguage('en')}></Flags.GB>
        </div>
        <div className="login-form">
          <h2>
            {t('loginpage.title', {
              framework: 'react-i18next',
            })}
          </h2>
          <p>
            {t('loginpage.intro', {
              framework: 'react-i18next',
            })}
            <Emoji symbol="😉"></Emoji>
          </p>
          <div id="inputFields">
            <input
              onKeyDown={handleKeyDown}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                if (error) {
                  setError(false);
                }
              }}
              required
              name="password"
              type="password"
              placeholder="* Password"
            />
          </div>
          {error && <span className="error">{t('loginpage.error')}</span>}

          {!loading && (
            <Button
              disabled={password === ''}
              type="primary"
              onClick={() => handleSubmit(password)}
              label={t('loginpage.loginbutton', {
                framework: 'react-i18next',
              })}
            ></Button>
          )}
          {loading && <Spinner></Spinner>}
        </div>
      </div>
    </>
  );
}
