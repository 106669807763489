import { Roles } from './roles';
import { Routes } from './routes';

export enum Permissions {
  NONE = 0,
  CAN_VISIT_WEBSITE = 1,
  CAN_SEND_RSVP = 2,
  CAN_ACCESS_ADMIN = 3,
}

export function getPermissionsForRole(role: Roles) {
  switch (role) {
    case Roles.GUEST:
      return Permissions.CAN_SEND_RSVP;
    case Roles.VISITOR:
      return Permissions.CAN_VISIT_WEBSITE;
    case Roles.ADMIN:
      return Permissions.CAN_ACCESS_ADMIN;
    default:
      return Permissions.NONE;
  }
}

export function getPermissionsForRoute(route: Routes) {
  switch (route) {
    case Routes.LOGIN:
      return Permissions.NONE;
    case Routes.HOME:
      return Permissions.CAN_VISIT_WEBSITE;
    case Routes.ADMIN:
      return Permissions.CAN_ACCESS_ADMIN;
    default:
      return Permissions.NONE;
  }
}

export function isRouteAllowedForRole(route: Routes, role: Roles) {
  const permissionsForRoute = getPermissionsForRoute(route);
  const permissionsForRole = getPermissionsForRole(role);
  return permissionsForRole >= permissionsForRoute;
}
