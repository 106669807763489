import React, { FunctionComponent } from 'react';
import Button from '../button/Button';
import Rsvp from './rsvp';
import { Zoom } from 'react-reveal';
import './rsvp-wizard.scss';
import { CSSTransition } from 'react-transition-group';
import { RSVPStates } from './rsvp-states';
import logo from '../../assets/images/logo.svg';
import { useTranslation } from 'react-i18next';

const rsvp = new Rsvp();

export default function RSVPWizard() {
  const [stateId, setStateId] = React.useState(rsvp.currentStateId);
  const [, setData] = React.useState(rsvp.getRsvpData());
  const { t } = useTranslation('common');

  React.useEffect(() => {
    rsvp.listenToStateChangeEvent(() => {
      setStateId(rsvp.currentStateId);
    });
    rsvp.listenToDataUpdateEvent((d) => {
      setData(d);
    });
  }, []);
  const [inProp, setInProp] = React.useState(false);

  return (
    <section className="rsvp-wizard" id="RSVP">
      <h2>{t('RSVP.title')}</h2>
      <Zoom>
        <div className="content">
          <CSSTransition
            onEntered={() => setInProp(false)}
            in={inProp}
            timeout={200}
            classNames="my-node"
          >
            {rsvp.render()}
          </CSSTransition>
          <div className="button-bar">
            {stateId !== RSVPStates.READY && stateId !== RSVPStates.END && (
              <Button
                disabled={!rsvp.canDoPrevious()}
                type="secondary"
                label={t('RSVP.prev')}
                onClick={() => {
                  rsvp.previous();
                  setInProp(true);
                }}
              ></Button>
            )}
            {stateId !== RSVPStates.END && stateId !== RSVPStates.SUBMIT && (
              <Button
                disabled={!rsvp.canDoNext()}
                type="secondary"
                label={t('RSVP.next')}
                onClick={() => {
                  rsvp.next();
                  setInProp(true);
                }}
              ></Button>
            )}
          </div>
          {stateId !== RSVPStates.END && (
            <img className="logo" src={logo} alt="logo" />
          )}
        </div>
      </Zoom>
    </section>
  );
}
