import React from 'react';
import ReactCardFlip from 'react-card-flip';
import Button from '../button/Button';
import './location-card.scss';
import { ReactComponent as FlipBack } from '../../assets/images/icons/back.svg';
import { useTranslation } from 'react-i18next';

interface LocationCardProps {
  front: JSX.Element;
  back: JSX.Element;
}

const LocationCard: React.FC<LocationCardProps> = ({ front, back }) => {
  const [isFlipped, setIsFlipped] = React.useState(false);
  const { t } = useTranslation('common');
  return (
    <ReactCardFlip
      containerClassName="location-card"
      flipDirection="horizontal"
      isFlipped={isFlipped}
    >
      <div className="location-card--front">
        {front}
        <Button
          label={t('location.map')}
          type="secondary"
          onClick={() => {
            setIsFlipped(!isFlipped);
          }}
        ></Button>
      </div>
      <div className="location-card--back">
        <FlipBack
          width={40}
          height={40}
          className="flip-back"
          onClick={() => setIsFlipped(false)}
        ></FlipBack>
        {back}
      </div>
    </ReactCardFlip>
  );
};

export default LocationCard;
