import React from 'react';
import {
  GoogleMap,
  LoadScript,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';
import './Map.scss';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../Countdown/Spinner';

interface MapProps {
  location: {
    iconUrl: string;
    position: google.maps.LatLngLiteral;
  };
}
const mapStyles = [
  {
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#6195a0',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        color: '#f2f2f2',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#e6f3d6',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#f4d2c5',
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text',
    stylers: [
      {
        color: '#4e4e4e',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#f4f4f4',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#787878',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        color: '#eaf6f8',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#eaf6f8',
      },
    ],
  },
];
const MapContainer: React.FC<MapProps> = ({ location }) => {
  const { t } = useTranslation('common');
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyAv0_HcMg25K9ZWurylBRe4bAympteAc_M', // ,
  });

  const opts: google.maps.MapOptions = {
    mapTypeControl: false,
    styles: mapStyles,
    streetViewControl: false,
    keyboardShortcuts: false,
    clickableIcons: false,
  };

  if (loadError) {
    return <div>{t('location.mapError')}</div>;
  }

  if (!isLoaded) {
    return <Spinner></Spinner>;
  }

  return (
    <GoogleMap
      options={opts}
      zoom={13}
      center={location.position}
      mapContainerClassName="map-container"
    >
      {location && (
        <Marker icon={location.iconUrl} position={location.position}></Marker>
      )}
    </GoogleMap>
  );
};
export default MapContainer;
