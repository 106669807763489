import React, { FunctionComponent } from 'react';
import Button from '../button/Button';
import Rsvp, { KeysMatching, RSVPData } from './rsvp';
import Lottie from 'react-lottie';
import hugAnimationData from '../../assets/lottie-animations/hugging.json';
import planeAnimationData from '../../assets/lottie-animations/paper-plane.json';
import remove from '../../assets/images/icons/remove.svg';
import './rsvp-state.scss';
import { useTranslation } from 'react-i18next';
import 'react-phone-number-input/style.css';
import PhoneCountrySelect from './PhoneCountrySelect';
import { rsvpService } from '../../services/RSVPService';
import i18next from 'i18next';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

export enum RSVPStates {
  READY = 'READY',
  NAME = 'NAME',
  PHONE = 'PHONE',
  WILL_MAKE_IT = 'WILL_MAKE_IT',
  NAME_GUESTS = 'NAME_GUESTS',
  KIDS = 'KIDS',
  FOOD_PREFERENCES = 'FOOD_PREFERENCES',
  ACCOMODATION = 'ACCOMODATION',
  TRANSPORT = 'TRANSPORT',
  SUBMIT = 'SUBMIT',
  END = 'END',
}

const isNonEmptyString = (str: string) => {
  return str?.length > 0;
};

export abstract class RsvpState {
  constructor(protected rsvp: Rsvp) {}
  protected abstract renderContent(): JSX.Element;
  abstract get stateId(): RSVPStates;
  abstract next(): void;
  abstract previous(): void;
  render(): JSX.Element {
    return <div className="rsvp-state">{this.renderContent()}</div>;
  }
  abstract canDoNext(): boolean;
  abstract canDoPrevious(): boolean;
}

function DisplayOnlyState({ text }) {
  const { t } = useTranslation('common');
  return (
    <div className="display-only">
      <span>{t(text)}</span>
    </div>
  );
}

export class StartState extends RsvpState {
  get stateId(): RSVPStates {
    return RSVPStates.READY;
  }
  next(): void {
    this.rsvp.changeState(new Name(this.rsvp));
  }
  previous(): void {
    console.warn('no previous state to transition to');
  }
  renderContent(): JSX.Element {
    return <DisplayOnlyState text="RSVP.start"></DisplayOnlyState>;
  }
  canDoNext(): boolean {
    return true;
  }
  canDoPrevious(): boolean {
    return false;
  }
}

interface TextValueAnswerProps {
  text: string;
  onChange: (value: string) => void;
  initialValue: string;
}

function TextValueAnswer({
  text,
  onChange,
  initialValue,
}: TextValueAnswerProps) {
  const [value, setValue] = React.useState(initialValue || '');
  const { t } = useTranslation('common');
  return (
    <div className="text-value-answer">
      <span>{t(text)}</span>
      <input
        type="text"
        onChange={(e) => {
          const val = e.target.value;
          setValue(val);
          onChange(val);
        }}
        value={value as string}
      ></input>
    </div>
  );
}

function TextAreaValueAnswer({
  text,
  onChange,
  initialValue,
}: TextValueAnswerProps) {
  const [value, setValue] = React.useState(initialValue || '');
  const { t } = useTranslation('common');
  return (
    <div className="text-value-answer">
      <span>{t(text)}</span>
      <textarea
        rows={5}
        onChange={(e) => {
          const val = e.target.value;
          setValue(val);
          onChange(val);
        }}
        value={value as string}
      ></textarea>
    </div>
  );
}

interface NumberValueAnswerProps {
  text: string;
  property: KeysMatching<RSVPData, number>;
  onChange: (value: number) => void;
  initialValue: number;
}

function NumberValueAnswer({
  text,
  onChange,
  initialValue,
}: NumberValueAnswerProps) {
  const [value, setValue] = React.useState(initialValue || '');
  const { t } = useTranslation('common');
  return (
    <div className="text-value-answer">
      <span>{t(text)}</span>
      <input
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setValue(val);
          onChange(Number.parseInt(val));
        }}
        value={value as string}
      ></input>
    </div>
  );
}

export class Name extends RsvpState {
  get stateId(): RSVPStates {
    return RSVPStates.NAME;
  }
  next(): void {
    this.rsvp.changeState(new Phone(this.rsvp));
  }
  previous(): void {
    console.warn('no previous state to transition to');
  }
  renderContent(): JSX.Element {
    return (
      <TextValueAnswer
        text="RSVP.fieldName"
        onChange={(val) => {
          this.rsvp.updateRsvpData({ key: 'name', value: val });
          let nameOfAllGuests = this.rsvp.getRsvpData().nameOfAllGuests;
          if (nameOfAllGuests === undefined) {
            nameOfAllGuests = [];
          }
          nameOfAllGuests[0] = val;
          this.rsvp.updateRsvpData({
            key: 'nameOfAllGuests',
            value: nameOfAllGuests,
          });
        }}
        initialValue={this.rsvp.getRsvpData().name}
      ></TextValueAnswer>
    );
  }
  canDoNext(): boolean {
    return isNonEmptyString(this.rsvp.getRsvpData().name);
  }
  canDoPrevious(): boolean {
    return false;
  }
}

function PhoneNumberState({
  initialPrefix,
  initialPhone,
  onPrefixChange,
  onPhoneChange,
}) {
  const [prefix, setPrefix] = React.useState(initialPrefix || '');
  const [phone, setPhone] = React.useState(initialPhone || '');
  const { t } = useTranslation('common');
  return (
    <div className="phone-number">
      <span>{t('RSVP.fieldPhone')}</span>
      <div className="phone-number-input">
        <PhoneCountrySelect
          value={prefix}
          onChange={(v) => {
            setPrefix(v);
            onPrefixChange(v);
          }}
        ></PhoneCountrySelect>
        <input
          value={phone as string}
          type="text"
          inputMode="decimal"
          pattern="[0-9]*"
          onKeyDown={(event) => {
            const allowedKeyCodes = [
              'ArrowLeft',
              'ArrowRight',
              'Delete',
              'Backspace',
            ];
            if (
              !allowedKeyCodes.includes(event.key) &&
              !event.key.match(/[0-9]/)
            ) {
              event.preventDefault();
            }
          }}
          onChange={(e) => {
            if (e.target.validity.valid) {
              setPhone(e.target.value);
              onPhoneChange(e.target.value);
            }
          }}
        ></input>
      </div>
    </div>
  );
}

export class Phone extends RsvpState {
  get stateId(): RSVPStates {
    return RSVPStates.PHONE;
  }
  next(): void {
    this.rsvp.changeState(new WillMakeIt(this.rsvp));
  }
  previous(): void {
    this.rsvp.changeState(new Name(this.rsvp));
  }
  renderContent(): JSX.Element {
    return (
      <PhoneNumberState
        onPrefixChange={(val) => {
          this.rsvp.updateRsvpData({ key: 'phonePrefix', value: val });
        }}
        onPhoneChange={(val) => {
          this.rsvp.updateRsvpData({ key: 'phone', value: val });
        }}
        initialPrefix={this.rsvp.getRsvpData().phonePrefix}
        initialPhone={this.rsvp.getRsvpData().phone}
      ></PhoneNumberState>
    );
  }
  canDoNext(): boolean {
    const prefix = this.rsvp.getRsvpData().phonePrefix;
    const phone = this.rsvp.getRsvpData().phone;
    const fullPhone = `+${prefix}${phone}`;
    return (
      isNonEmptyString(prefix) &&
      isNonEmptyString(phone) &&
      isPossiblePhoneNumber(fullPhone)
    );
  }
  canDoPrevious(): boolean {
    return true;
  }
}

const RadioButton = ({ label, value, onChange }) => {
  return (
    <label>
      <input type="radio" checked={value} onChange={onChange} />
      <span>{label}</span>
    </label>
  );
};

function WillMakeItState({ willMakeIt, onChange }) {
  const { t } = useTranslation('common');

  return (
    <div className="will-make-it">
      <span>{t('RSVP.fieldAttendance')}</span>
      <RadioButton
        label={t('RSVP.fieldAttendanceYes')}
        value={willMakeIt === true}
        onChange={() => onChange(true)}
      />
      <RadioButton
        label={t('RSVP.fieldAttendanceNo')}
        value={willMakeIt === false}
        onChange={() => onChange(false)}
      />
    </div>
  );
}

export class WillMakeIt extends RsvpState {
  get stateId(): RSVPStates {
    return RSVPStates.WILL_MAKE_IT;
  }
  next(): void {
    const canMakeIt = this.rsvp.getRsvpData().canParticipate;
    if (canMakeIt === true) {
      this.rsvp.changeState(new NameOfAllPeopleComing(this.rsvp));
    } else if (canMakeIt === false) {
      this.rsvp.changeState(new Submit(this.rsvp));
    }
  }
  previous(): void {
    this.rsvp.changeState(new Phone(this.rsvp));
  }
  renderContent(): JSX.Element {
    return (
      <WillMakeItState
        willMakeIt={this.rsvp.getRsvpData().canParticipate}
        onChange={(v) =>
          this.rsvp.updateRsvpData({
            key: 'canParticipate',
            value: v,
          })
        }
      ></WillMakeItState>
    );
  }
  canDoNext(): boolean {
    return this.rsvp.getRsvpData().canParticipate !== undefined;
  }
  canDoPrevious(): boolean {
    return true;
  }
}

function NameOfAllPeopleComingState({
  nameOfAllGuests,
  principalGuest,
  onChange,
}: {
  nameOfAllGuests: string[];
  principalGuest: string;
  onChange: Function;
}) {
  const [names, setNames] = React.useState(nameOfAllGuests || []);
  const [currentName, setCurrentName] = React.useState('');
  const { t } = useTranslation('common');

  return (
    <div className="people-coming">
      <span>{t('RSVP.fieldNames')}</span>
      <ul>
        {names.map((n) => {
          return (
            <li key={n}>
              <span>{n}</span>
              {n !== principalGuest && (
                <img
                  onClick={() => {
                    const newNames = names.filter((name) => name !== n);
                    setNames(newNames);
                    onChange(newNames);
                  }}
                  alt="remove"
                  src={remove}
                />
              )}
            </li>
          );
        })}
      </ul>
      <div>
        <input
          type="text"
          value={currentName}
          onChange={(e) => setCurrentName(e.target.value)}
        ></input>
        <Button
          disabled={currentName.trim() === ''}
          type="primary"
          label={t('RSVP.fieldNamesAdd')}
          onClick={() => {
            const newNames = names.concat(currentName);
            setNames(newNames);
            setCurrentName('');
            onChange(newNames);
          }}
        ></Button>
      </div>
    </div>
  );
}

export class NameOfAllPeopleComing extends RsvpState {
  get stateId(): RSVPStates {
    return RSVPStates.NAME_GUESTS;
  }
  next(): void {
    this.rsvp.changeState(new HowManyKids(this.rsvp));
  }
  previous(): void {
    this.rsvp.changeState(new WillMakeIt(this.rsvp));
  }
  renderContent(): JSX.Element {
    return (
      <NameOfAllPeopleComingState
        principalGuest={this.rsvp.getRsvpData().name}
        nameOfAllGuests={this.rsvp.getRsvpData().nameOfAllGuests}
        onChange={(newNames) => {
          this.rsvp.updateRsvpData({ key: 'nameOfAllGuests', value: newNames });
        }}
      ></NameOfAllPeopleComingState>
    );
  }
  canDoNext(): boolean {
    return true;
  }
  canDoPrevious(): boolean {
    return true;
  }
}

export class HowManyKids extends RsvpState {
  get stateId(): RSVPStates {
    return RSVPStates.KIDS;
  }
  next(): void {
    this.rsvp.changeState(new FoodPreferences(this.rsvp));
  }
  previous(): void {
    this.rsvp.changeState(new NameOfAllPeopleComing(this.rsvp));
  }
  renderContent(): JSX.Element {
    return (
      <NumberValueAnswer
        text="RSVP.fieldKids"
        property="kids"
        initialValue={this.rsvp.getRsvpData().kids}
        onChange={(v) => {
          this.rsvp.updateRsvpData({ key: 'kids', value: v });
        }}
      ></NumberValueAnswer>
    );
  }
  canDoNext(): boolean {
    return true;
  }
  canDoPrevious(): boolean {
    return true;
  }
}

export class FoodPreferences extends RsvpState {
  get stateId(): RSVPStates {
    return RSVPStates.FOOD_PREFERENCES;
  }
  next(): void {
    this.rsvp.changeState(new Accomodation(this.rsvp));
  }
  previous(): void {
    this.rsvp.changeState(new HowManyKids(this.rsvp));
  }
  renderContent(): JSX.Element {
    return (
      <TextAreaValueAnswer
        text="RSVP.fieldAllergies"
        initialValue={this.rsvp.getRsvpData().foodRestrictionsOrPreferences}
        onChange={(v) => {
          this.rsvp.updateRsvpData({
            key: 'foodRestrictionsOrPreferences',
            value: v,
          });
        }}
      ></TextAreaValueAnswer>
    );
  }
  canDoNext(): boolean {
    return true;
  }
  canDoPrevious(): boolean {
    return true;
  }
}

function YesNoValueAnswer({ text, value, onChange }) {
  const { t } = useTranslation('common');

  return (
    <div className="will-make-it">
      <span>{t(text)}</span>
      <RadioButton
        label={t('RSVP.fieldYes')}
        value={value === true}
        onChange={() => onChange(true)}
      />
      <RadioButton
        label={t('RSVP.fieldNo')}
        value={value === false}
        onChange={() => onChange(false)}
      />
    </div>
  );
}

export class Accomodation extends RsvpState {
  get stateId(): RSVPStates {
    return RSVPStates.ACCOMODATION;
  }
  next(): void {
    this.rsvp.changeState(new Transport(this.rsvp));
  }
  previous(): void {
    this.rsvp.changeState(new FoodPreferences(this.rsvp));
  }
  renderContent(): JSX.Element {
    return (
      <YesNoValueAnswer
        value={this.rsvp.getRsvpData().accomodationNeeded}
        text="RSVP.fieldAccomodation"
        onChange={(v) => {
          this.rsvp.updateRsvpData({ key: 'accomodationNeeded', value: v });
        }}
      ></YesNoValueAnswer>
    );
  }
  canDoNext(): boolean {
    return this.rsvp.getRsvpData().accomodationNeeded !== undefined;
  }
  canDoPrevious(): boolean {
    return true;
  }
}

export class Transport extends RsvpState {
  get stateId(): RSVPStates {
    return RSVPStates.TRANSPORT;
  }
  next(): void {
    this.rsvp.changeState(new Submit(this.rsvp));
  }
  previous(): void {
    this.rsvp.changeState(new Accomodation(this.rsvp));
  }
  renderContent(): JSX.Element {
    return (
      <YesNoValueAnswer
        value={this.rsvp.getRsvpData().transportNeeded}
        text="RSVP.fieldTransport"
        onChange={(v) => {
          this.rsvp.updateRsvpData({ key: 'transportNeeded', value: v });
        }}
      ></YesNoValueAnswer>
    );
  }
  canDoNext(): boolean {
    return this.rsvp.getRsvpData().transportNeeded !== undefined;
  }
  canDoPrevious(): boolean {
    return true;
  }
}

const SubmitState: FunctionComponent<{ onSubmit: Function }> = ({
  onSubmit,
}) => {
  const [submit, setSubmit] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const { t } = useTranslation('common');

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: planeAnimationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  React.useEffect(() => {
    async function callOnSubmit() {
      setSubmitting(true);
      try {
        await onSubmit();
        setSubmitting(false);
      } catch {
        setSubmitting(false);
      }
    }

    if (submit) {
      setSubmit(false);
      callOnSubmit();
    }
  }, [submit, onSubmit]);

  return (
    <div className="submit">
      <span>{t('RSVP.fieldSubmit')}</span>
      {submitting && (
        <Lottie options={defaultOptions} height={300} width={300} />
      )}
      {!submitting && (
        <Button
          label={t('RSVP.confirm')}
          type="primary"
          onClick={() => setSubmit(true)}
        ></Button>
      )}
    </div>
  );
};

export class Submit extends RsvpState {
  get stateId(): RSVPStates {
    return RSVPStates.SUBMIT;
  }
  next(): void {
    this.rsvp.changeState(new ThankYou(this.rsvp));
  }
  previous(): void {
    this.rsvp.changeState(new Transport(this.rsvp));
  }
  renderContent(): JSX.Element {
    return (
      <SubmitState
        onSubmit={async () => {
          const data = this.rsvp.getRsvpData();
          await rsvpService.sendRSVP({
            name: data.name,
            phonePrefix: data.phonePrefix,
            phone: data.phone,
            canParticipate: data.canParticipate,
            nameOfAllGuests: data.nameOfAllGuests,
            kids: data.kids,
            foodRestrictionsOrPreferences: data.foodRestrictionsOrPreferences,
            accomodationNeeded: data.accomodationNeeded,
            transportNeeded: data.transportNeeded,
            language: i18next.language,
          });
          this.next();
        }}
      ></SubmitState>
    );
  }
  canDoNext(): boolean {
    return false;
  }
  canDoPrevious(): boolean {
    return true;
  }
}

function ThankYouState({ text }) {
  const { t } = useTranslation('common');
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: hugAnimationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <div className="thank-you">
      <h3>{t('RSVP.fieldThanks')}</h3>
      <span>{t(text)}</span>
      <Lottie options={defaultOptions} height={300} width={300} />
    </div>
  );
}

export class ThankYou extends RsvpState {
  get stateId(): RSVPStates {
    return RSVPStates.END;
  }
  next(): void {
    console.warn('no next state to transition to');
  }
  previous(): void {
    console.warn('no previous state to transition to');
  }
  renderContent(): JSX.Element {
    const text = this.rsvp.getRsvpData().canParticipate
      ? 'RSVP.fieldThanksJoining'
      : 'RSVP.fieldThanksNotJoining';
    return <ThankYouState text={text}></ThankYouState>;
  }
  canDoNext(): boolean {
    return false;
  }
  canDoPrevious(): boolean {
    return false;
  }
}
