import { http } from './Http';

export type RSVPDataDto = {
  name: string;
  phonePrefix: string;
  phone: string;
  canParticipate: boolean;
  nameOfAllGuests: string[];
  kids?: number;
  foodRestrictionsOrPreferences?: string;
  accomodationNeeded?: boolean;
  transportNeeded?: boolean;
  language: string;
};
class RSVPService {
  async sendRSVP(rsvp: RSVPDataDto) {
    await http.post<RSVPDataDto, {}>('/rsvp', rsvp);
  }
}

export const rsvpService = new RSVPService();
