import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Zoom } from 'react-reveal';

import final from '../../assets/images/final-image.svg';
import './end-section.scss';

export interface IEndSectionProps {}

export function EndSection(props: IEndSectionProps) {
  const { t } = useTranslation('common');
  return (
    <section className="end-section">
      <Zoom>
        <h3>{t('end.greetings')}</h3>
        <img src={final} alt="thanks"></img>
      </Zoom>
    </section>
  );
}
