import React from 'react';
import './Navbar.scss';

import { HashLink } from 'react-router-hash-link';
import i18next from '../i18next';
import { useTranslation } from 'react-i18next';

function Link(props) {
  return <HashLink {...props} smooth></HashLink>;
}

function Navbar() {
  const { t, i18n } = useTranslation('common');
  return (
    <nav>
      <input type="checkbox" id="menu-toggle" />
      <label id="trigger" htmlFor="menu-toggle" />
      <label id="burger" htmlFor="menu-toggle" />
      <ul id="menu">
        <li>
          <Link to="#location">
            <i className="fas fa-map" />
            <span className="description">Location</span>
          </Link>
        </li>
        <li>
          <Link to="#RSVP">
            <i className="fas fa-envelope-open" />
            <span className="description">
              {t('navbar.RSVP', {
                framework: 'react-i18next',
              })}
            </span>
          </Link>
        </li>
        <li>
          <Link to="#aboutus">
            <i className="fas fa-edit" />
            <span className="description">
              {t('navbar.contactus', {
                framework: 'react-i18next',
              })}
            </span>
          </Link>
        </li>
        <li>
          <Link to="#gifts">
            <i className="fas fa-gift" />
            <span className="description">
              {t('navbar.gifts', {
                framework: 'react-i18next',
              })}
            </span>
          </Link>
        </li>
        <li>
          <ul>
            <li>
              <span
                className={`language-switch ${
                  i18next.language === 'it' ? 'active' : ''
                }`}
                onClick={() => i18n.changeLanguage('it')}
              >
                IT
              </span>
            </li>
            <li>|</li>
            <li>
              <span
                className={`language-switch ${
                  i18next.language === 'en' ? 'active' : ''
                }`}
                onClick={() => i18n.changeLanguage('en')}
              >
                EN
              </span>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
