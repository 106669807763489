import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/modules/a11y/a11y.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss';
import 'swiper/modules/scrollbar/scrollbar.scss';
import 'swiper/modules/autoplay/autoplay.scss';
import './image-slider.scss';

interface SliderProps {
  items: { img: string }[];
  autoplay?: boolean;
  navigation?: boolean;
  scrollbar?: boolean;
}

const Slider: React.FC<SliderProps> = ({
  items,
  autoplay = false,
  navigation = false,
  scrollbar = true,
}) => {
  return (
    <div className="slider-container">
      <Swiper
        autoHeight
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        autoplay={autoplay}
        className="slider"
        spaceBetween={50}
        scrollbar={scrollbar ? { draggable: true } : false}
        navigation={navigation}
      >
        {items.map((item) => {
          return (
            <SwiperSlide key={item.img}>
              <div className="slide-content">
                <img src={item.img} alt=""></img>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Slider;
