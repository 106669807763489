import React from 'react';
import './Hero.scss';
import Countdown from '../Countdown/Countdown';

export default function Hero() {
  return (
    <section className="header" id="hero">
      <div id="tagline">
        <h1>Viviana + Stefano</h1>
        <div className="wedding-date">
          <hr></hr>
          <h3>04.06.2022</h3>
          <hr></hr>
        </div>
      </div>
      <Countdown endDate={new Date('2022-06-04T11:00:00')} />
    </section>
  );
}
