import React from 'react';
import ReactCardFlip from 'react-card-flip';
import Button from '../button/Button';
import './contribute-card.scss';
import { useTranslation } from 'react-i18next';

interface ContributeCardProps {
  front: JSX.Element;
  back: JSX.Element;
}

const ContributeCard: React.FC<ContributeCardProps> = ({ front, back }) => {
  const [isFlipped, setIsFlipped] = React.useState(false);
  const { t } = useTranslation('common');
  return (
    <ReactCardFlip
      containerClassName="contribute-card"
      flipDirection="horizontal"
      isFlipped={isFlipped}
    >
      <div className="contribute-card--front">
        {front}
        <Button
          label={t('gifts.contribute')}
          type="secondary"
          onClick={() => {
            setIsFlipped(!isFlipped);
          }}
        ></Button>
      </div>
      <div className="contribute-card--back">{back}</div>
    </ReactCardFlip>
  );
};

export default ContributeCard;
