import * as React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { MessageModel } from '../../services/AdminService';
import './responses-grid.scss';
import { renderCellExpand } from './GridCellExpand';

const columns: GridColDef[] = [
  { field: 'sentDate', headerName: 'Sent Date' },
  { field: 'phoneNumber', headerName: 'Phone' },
  {
    field: 'message',
    headerName: 'Message',
    renderCell: renderCellExpand,
    width: 500,
  },
];

export default function SmsMessagesGrid({
  messages,
}: {
  messages: MessageModel[];
}) {
  return (
    <div className="responses-grid">
      <DataGrid rows={messages} columns={columns} />
    </div>
  );
}
