import React from 'react';
import { ReactComponent as Divider } from '../../assets/images/section-divider.svg';
import './section-divider.scss';

const Sectiondivider: React.FC<{}> = (props) => {
  return (
    <div className="section-divider">
      <Divider className="divider-image"></Divider>
    </div>
  );
};

export default Sectiondivider;
