import React from 'react';
import Map from '../Map/Map';
import Slider from '../image-slider/ImageSlider';
import './Location.scss';
import LocationSite from './LocationSite';
import img1 from '../../assets/images/campanile_1.jpg';
import img2 from '../../assets/images/campanile_2.jpeg';
import img3 from '../../assets/images/castello_1.jpeg';
import img4 from '../../assets/images/castello_2.jpeg';
import img5 from '../../assets/images/castello_3.jpeg';
import img6 from '../../assets/images/castello_4.jpeg';
import img7 from '../../assets/images/castello_5.jpeg';
import LocationCard from './LocationCard';
import churchMarker from '../../assets/images/icons/church-map-icon.svg';
import partyMarker from '../../assets/images/icons/party-map-icon.svg';
import { ReactComponent as ChurchIcon } from '../../assets/images/icons/church.svg';
import { ReactComponent as PartyIcon } from '../../assets/images/icons/party.svg';
import { ReactComponent as PlaneIcon } from '../../assets/images/icons/plane.svg';
import { ReactComponent as CarIcon } from '../../assets/images/icons/car.svg';
import { ReactComponent as TrainIcon } from '../../assets/images/icons/train.svg';
import { VenueInfo } from './VenueInfo';
import Button from '../button/Button';
import { useTranslation } from 'react-i18next';

const churchImages = [
  {
    img: img1,
  },
  {
    img: img2,
  },
];

const restaurantImages = [
  {
    img: img3,
  },
  {
    img: img4,
  },
  {
    img: img5,
  },
  {
    img: img6,
  },
  {
    img: img7,
  },
];

const renderVenueCard = (
  t: any,
  markerUrl: string,
  markerPosition: { lat: number; lng: number },
  directionsUrl: string,
  venueInfo: {
    time: string;
    location: string;
    street: string;
    city: string;
    region: string;
  }
): JSX.Element => {
  return (
    <LocationCard
      front={
        <VenueInfo
          time={venueInfo.time}
          location={venueInfo.location}
          street={venueInfo.street}
          city={venueInfo.city}
          region={venueInfo.region}
          icon={markerUrl}
        ></VenueInfo>
      }
      back={
        <>
          <Map
            location={{
              iconUrl: markerUrl,
              position: markerPosition,
            }}
          />
          <Button
            label={t('location.directions')}
            type="secondary"
            onClick={() => window.open(directionsUrl, '_blank')}
          ></Button>
        </>
      }
    ></LocationCard>
  );
};

const renderChurchLocation = (t: any): JSX.Element => {
  return (
    <LocationSite>
      <>
        <div className="location-heading">
          <div>
            <ChurchIcon width={45}></ChurchIcon>
            <h3>{t('location.church-title')}</h3>
          </div>
        </div>

        {renderVenueCard(
          t,

          churchMarker,
          {
            lat: 40.915822,
            lng: 14.5459786,
          },
          'https://goo.gl/maps/oQZyKV3dCgs1W65J6',
          {
            time: '11:00',
            location: 'Chiesa S.Paolo Eremita e S.S. Epifania',
            street: 'Via Roma 5',
            city: 'San Paolo Bel Sito',
            region: '80030, Napoli',
          }
        )}
        <Slider items={churchImages}></Slider>
      </>
    </LocationSite>
  );
};
const renderRestaurantLocation = (t: any): JSX.Element => {
  return (
    <LocationSite>
      <>
        <div className="location-heading">
          <div>
            <PartyIcon width={45}></PartyIcon>
            <h3>{t('location.party-title')}</h3>
          </div>
        </div>

        {renderVenueCard(
          t,
          partyMarker,
          {
            lat: 40.8805508,
            lng: 14.630696,
          },
          'https://goo.gl/maps/D8Pv4tM6cJDphCGb9',
          {
            time: '13:00',
            location: 'Castello Lancellotti',
            street: 'Piazza castello',
            city: 'Lauro',
            region: ' 83023, Avellino',
          }
        )}
        <Slider items={restaurantImages}></Slider>
      </>
    </LocationSite>
  );
};

export default function Location() {
  const { t } = useTranslation('common');
  return (
    <div className="location section" id="location">
      <h2>Location</h2>
      <p>
        {t('location.introduction', {
          framework: 'react-i18next',
        })}
      </p>
      <div className="map-locations-container">
        {renderChurchLocation(t)}
        {renderRestaurantLocation(t)}
      </div>
      <h2>
        {t('location.getting-there', {
          framework: 'react-i18next',
        })}
      </h2>
      <div id="directions">
        <div className="transportList">
          <h3>
            <PlaneIcon width={40} height={40}></PlaneIcon>
            {t('location.plane', {
              framework: 'react-i18next',
            })}
          </h3>
          <span className="detailedDirections">
            {t('location.planedescription1', {
              framework: 'react-i18next',
            })}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.anm.it/index.php?option=com_content&task=view&id=1314"
            >
              {t('location.planedescription2', {
                framework: 'react-i18next',
              })}
            </a>
            {t('location.planedescription3', {
              framework: 'react-i18next',
            })}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.eavsrl.it/web/orari-linee-ferroviarie-emergenza-covid-19-rimodulazione-servizio-vigore"
            >
              {t('location.planedescription4', {
                framework: 'react-i18next',
              })}
            </a>
            {t('location.planedescription5', {
              framework: 'react-i18next',
            })}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.aeroportodinapoli.it/in-arrivo/autonoleggi"
            >
              {t('location.planedescription6', {
                framework: 'react-i18next',
              })}
            </a>
            {t('location.planedescription7', {
              framework: 'react-i18next',
            })}
          </span>
        </div>
        <div className="transportList">
          <h3>
            <CarIcon width={40} height={40}></CarIcon>
            {t('location.car', {
              framework: 'react-i18next',
            })}
          </h3>
          <span className="detailedDirections">
            {t('location.cardescription', {
              framework: 'react-i18next',
            })}
          </span>
        </div>
        <div className="transportList">
          <h3>
            <TrainIcon width={40} height={40}></TrainIcon>
            {t('location.train', {
              framework: 'react-i18next',
            })}
          </h3>
          <span className="detailedDirections">
            {t('location.traindescription', {
              framework: 'react-i18next',
            })}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.trenitalia.com/it.html"
            >
              {t('location.traindescription2', {
                framework: 'react-i18next',
              })}
            </a>

            {t('location.traindescription3', {
              framework: 'react-i18next',
            })}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.italotreno.it/it"
            >
              {t('location.traindescription4', {
                framework: 'react-i18next',
              })}
            </a>

            {t('location.traindescription5', {
              framework: 'react-i18next',
            })}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.eavsrl.it/web/orari-linee-ferroviarie-emergenza-covid-19-rimodulazione-servizio-vigore"
            >
              {t('location.traindescription6', {
                framework: 'react-i18next',
              })}
            </a>

            {t('location.traindescription7', {
              framework: 'react-i18next',
            })}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.avisautonoleggio.it/guida-con-avis/localit%C3%A0-noleggio-auto/europa/italia/napoli/stazione-centrale-napoli#"
            >
              {t('location.traindescription8', {
                framework: 'react-i18next',
              })}
            </a>

            {t('location.traindescription9', {
              framework: 'react-i18next',
            })}
          </span>
        </div>
      </div>
    </div>
  );
}
