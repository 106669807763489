import * as React from 'react';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { RSVPModel } from '../../services/AdminService';
import './responses-grid.scss';
import { renderCellExpand } from './GridCellExpand';

const columns: GridColDef[] = [
  {
    field: 'latestUpdateDate',
    headerName: 'Latest Update Date',
    renderCell: renderCellExpand,
  },
  { field: 'name', headerName: 'Name', renderCell: renderCellExpand },
  { field: 'language', headerName: 'Language' },
  { field: 'canParticipate', headerName: 'Will Participate' },
  {
    field: 'nameOfAllGuests',
    headerName: 'Name of All Guests',
    width: 150,
    renderCell: renderCellExpand,
  },
  {
    field: 'kids',
    headerName: 'Kids',
    width: 100,
    renderCell: renderCellExpand,
  },
  {
    field: 'foodRestrictionsOrPreferences',
    headerName: 'Food Restrictions Or Preferences',
    renderCell: renderCellExpand,
    width: 150,
  },
  { field: 'transportNeeded', headerName: 'Transport Needed', width: 150 },
  {
    field: 'accomodationNeeded',
    headerName: 'Accomodation Needed',
    width: 100,
    renderHeader: renderCellExpand,
  },
];

export default function ResponsesDataGrid({
  onSelect,
  responses,
}: {
  onSelect: Function;
  responses: RSVPModel[];
}) {
  return (
    <div className="responses-grid">
      <DataGrid
        rows={responses}
        columns={columns}
        onRowClick={(e) => {
          onSelect(e.row.id);
        }}
      />
    </div>
  );
}
