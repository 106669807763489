import React from 'react';
import { useTranslation } from 'react-i18next';
import './iban-card.scss';
import { printFormat } from 'iban';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import copy from '../../assets/images/icons/copy.svg';
import check from '../../assets/images/icons/check.svg';
import Fade from 'react-reveal/Fade';

export interface IbanCardProps {
  accountHolder: string;
  iban: string;
  bic: string;
  img: string;
  currency: string;
}
export default function IbanCard({
  accountHolder,
  iban,
  bic,
  img,
  currency,
}: IbanCardProps) {
  const { t } = useTranslation('common');
  const [copied, setCopied] = React.useState(false);

  function onCopy() {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }

  return (
    <div className="iban-card">
      <img width={55} src={img} alt="iban-country"></img>
      <div className="holder">
        <label>{t('gifts.accountHolder')}</label>
        <span>{accountHolder}</span>
      </div>
      <div className="iban">
        <label>IBAN</label>
        <div className="iban-content">
          <span>{printFormat(iban, ' ')}</span>

          <CopyToClipboard text={iban} onCopy={onCopy}>
            <div className="copy-area">
              <img className="copy" src={copy} alt="copy" width={25} />
              {copied && (
                <Fade when={copied}>
                  <img className="check" src={check} alt="copy" width={15} />
                </Fade>
              )}
            </div>
          </CopyToClipboard>
        </div>
      </div>

      <div className="bic">
        <label>BIC</label>
        <span>{bic}</span>
      </div>

      <div className="currency">
        <label>{t('gifts.currency')}</label>
        <span>{currency}</span>
      </div>
    </div>
  );
}
