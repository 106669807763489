import React from 'react';
import { useTranslation } from 'react-i18next';
import { Zoom } from 'react-reveal';
import './Countdown.scss';
import { Spinner } from './Spinner';

function calculateCountdown(endDate: Date) {
  let diff = (endDate.getTime() - new Date().getTime()) / 1000;

  // clear countdown when date is reached
  if (diff <= 0) return false;

  const timeLeft = {
    years: 0,
    days: 0,
    hours: 0,
    min: 0,
    sec: 0,
  };

  // calculate time difference between now and expected date
  if (diff >= 365.25 * 86400) {
    // 365.25 * 24 * 60 * 60
    timeLeft.years = Math.floor(diff / (365.25 * 86400));
    diff -= timeLeft.years * 365.25 * 86400;
  }
  if (diff >= 86400) {
    // 24 * 60 * 60
    timeLeft.days = Math.floor(diff / 86400);
    diff -= timeLeft.days * 86400;
  }
  if (diff >= 3600) {
    // 60 * 60
    timeLeft.hours = Math.floor(diff / 3600);
    diff -= timeLeft.hours * 3600;
  }
  if (diff >= 60) {
    timeLeft.min = Math.floor(diff / 60);
    diff -= timeLeft.min * 60;
  }
  timeLeft.sec = Math.floor(diff);

  return timeLeft;
}

function addLeadingZeros(value) {
  value = String(value);
  while (value.length < 2) {
    value = '0' + value;
  }
  return value;
}

export default function Countdown({ endDate }: { endDate: Date }) {
  const interval = React.useRef(null);
  const [countDown, setCountDown] = React.useState({
    days: 0,
    hours: 0,
    min: 0,
    sec: 0,
  });

  const [loading, setLoading] = React.useState(true);
  const { t } = useTranslation('common');

  const stop = () => {
    clearInterval(interval.current);
  };

  React.useEffect(() => {
    // update every second
    interval.current = setInterval(() => {
      const date = calculateCountdown(endDate);
      date
        ? setCountDown({
            ...date,
          })
        : stop();
    }, 1000);

    setTimeout(() => {
      setLoading(false);
    }, 2500);

    return () => stop();
  }, [endDate]);

  if (loading) {
    return <Spinner></Spinner>;
  }
  return (
    <Zoom>
      <div className="Countdown">
        <span className="Countdown-col">
          <span className="Countdown-col-element">
            <strong>{addLeadingZeros(countDown.days)}</strong>
            <span>{countDown.days === 1 ? t('hero.day') : t('hero.days')}</span>
          </span>
        </span>

        <span className="Countdown-col">
          <span className="Countdown-col-element">
            <strong>{addLeadingZeros(countDown.hours)}</strong>
            <span>{t('hero.hours')}</span>
          </span>
        </span>

        <span className="Countdown-col">
          <span className="Countdown-col-element">
            <strong>{addLeadingZeros(countDown.min)}</strong>
            <span>{t('hero.minutes')}</span>
          </span>
        </span>

        <span className="Countdown-col">
          <span className="Countdown-col-element">
            <strong>{addLeadingZeros(countDown.sec)}</strong>
            <span>{t('hero.seconds')}</span>
          </span>
        </span>
      </div>
    </Zoom>
  );
}
