import { http } from './Http';

export interface RSVPModel {
  id: string;
  latestUpdateDate: Date;
  nameOfAllGuests: string[];
  foodRestrictionsOrPreferences?: string;
  kids?: number;
  transportNeeded: boolean;
  phone: string;
  name: string;
  canParticipate: boolean;
  accomodationNeeded: boolean;
  language: string;
}

interface GetRSVPSResponse {
  guests: RSVPModel[];
}

export interface MessageModel {
  phoneNumber: string;
  message: string;
  id: string;
  sentDate: Date;
  snsId: string;
}

interface GetMessagesResponse {
  messages: MessageModel[];
}

class AdminService {
  async getGuestResponses(): Promise<RSVPModel[]> {
    try {
      const resp = await http.get<GetRSVPSResponse>('/rsvps');
      return resp.data.guests.map((m) => {
        return {
          ...m,
          id: m.phone,
        };
      });
    } catch {
      return [];
    }
  }

  async getMessages(): Promise<MessageModel[]> {
    try {
      const resp = await http.get<GetMessagesResponse>('/messages');
      return resp.data.messages;
    } catch {
      return [];
    }
  }
}

const adminService = new AdminService();
export default adminService;
